import { AnyObject, Dictionary, keysFrom, Trackable, TrackingScopes } from '@moda/portal-astrarium';
import {
  always,
  anyPass,
  dissoc,
  has,
  ifElse,
  is,
  isEmpty,
  isNil,
  mergeLeft,
  pipe,
  reject,
  toPairs,
  uniq
} from 'ramda';
import { Availability, FeatureFlagResults } from '@moda/portal-stanchions';
import { getActiveVariantBy } from '../lib/getActiveVariantBy';
import { isDiscounted } from '../lib/variantUtils/isDiscounted';
import { Cart, LineItem } from '../hooks/useCart';
import { VariantCellFragment, ProductDetailsFragment, UseUserQuery } from '../generated/types';
import { PaymentMethod as Payment } from '../hooks/usePaymentMethods/usePaymentMethodsQuery';
import { RestAddress as Address } from '../hooks/useAddress';
import { ReturnSummary } from '../hooks/useReturnSummary';
import { getStoredCart } from '../hooks/useCjCart';
// pretty much every property that Segment asks for is in snake_case.

type User = NonNullable<UseUserQuery['user']>;

const getVariantFromScope = (scope: TrackingScopes) => {
  if (scope?.variant) return scope.variant as VariantCellFragment | ProductDetailsFragment;
  if (scope?.masterVariant) return scope as unknown as VariantCellFragment | ProductDetailsFragment;
};

const getLineItemFromScope = (scope: TrackingScopes) => {
  if (scope?.lineItem) return scope.lineItem as LineItem;
  if (scope?.attributes) return scope as LineItem;
};

/**
findVariantInventoryType: This function is here to help with GTM tags.
It returns "Boutique" if the product in scope is a boutique product, or "Trunkshow" otherwise.
These values are intentionally Title Case.
*/
export const findVariantInventoryType = (scope: TrackingScopes) => {
  const variant = getVariantFromScope(scope);
  const lineItem = getLineItemFromScope(scope);

  if (variant)
    return variant.availability === Availability.Trunkshow.toString() ? 'Trunkshow' : 'Boutique';

  if (lineItem) return lineItem.attributes.isTrunkshow ? 'Trunkshow' : 'Boutique';
};

/**
findVariantDetailedInventoryType: This function helps with a GA custom dimension.
It returns one of the following:
- "Boutique"
- "Boutique-Preorder"
- "Boutique-Markdown"
- "Trunkshow"
*/
const findVariantDetailedInventoryType = (scope: TrackingScopes) => {
  const variant = getVariantFromScope(scope);
  const lineItem = getLineItemFromScope(scope);

  const availability = variant
    ? Number(variant.availability)
    : lineItem
      ? lineItem.attributes.isAvailableNow
        ? Availability.AvailableNow
        : lineItem.attributes.isTrunkshow
          ? Availability.Trunkshow
          : Availability.Preorder
      : null;
  const prices = variant
    ? getActiveVariantBy<
        VariantCellFragment['masterVariant'] | ProductDetailsFragment['masterVariant']
      >(variant, 'id', variant.id)?.prices
    : lineItem
      ? lineItem.attributes.prices
      : null;

  if (!availability || !prices) return;

  const availabilities = {
    [Availability.AvailableNow]: 'Boutique',
    [Availability.Preorder]: 'Boutique-Preorder',
    [Availability.Trunkshow]: 'Trunkshow'
  };

  return [availabilities[availability], isDiscounted(prices) ? 'Markdown' : null]
    .filter(Boolean)
    .join('-');
};

/**
getSearchIndex: This function is here to help with Algolia analytics
For product lists, the index that the product item came from is included with
each product.  The way we use Algolia, searches always come from one index at a time.
So, we only need to find the first item in order to pull out the index used.
*/
const getSearchIndex = (scope: TrackingScopes) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const products = scope?.products as Record<string, any>[] | undefined;
  if (!products?.length) return undefined;
  return products[0].index;
};

/**
getBrandForBrandedPLP: This function is here to help with GTM tags.
If the PLP is all from one designer, return that designer as the brand name.
If the PLP is _not_ all from one designer, then set the brand to "" (empty string).
Otherwise, return undefined - thereby not setting the brand.
*/
const getBrandForBrandedPLP = (scope: TrackingScopes) => {
  const products = scope?.products as ProductDetailsFragment[] | undefined;
  if (products === undefined) return undefined;
  const brand = pipe(
    (products: ProductDetailsFragment[]) => products.map(product => product.designerName),
    uniq,
    reject<string>(isNil),
    ifElse(
      brands => brands.length === 1,
      item => item[0],
      always('')
    )
  )(products);
  return brand;
};

/**
mapToCjProduct: This function's purpose is to take an existing structure of product data
and transforms it to a CJ compatible structure
*/
const mapToCjProduct = (product: AnyObject) => {
  const quantity = Math.floor(Number(product.attributes?.quantity || 1));
  const unitPrice = Number(product.attributes?.prices?.originalPrice?.price || 0);
  const discount = Number(product.attributes?.prices?.markdownDiscount?.price || 0);

  return {
    unitPrice: String(unitPrice),
    itemId: String(product.attributes?.skuId),
    discount: String(discount),
    quantity: String(quantity)
  };
};

const loginTypeDefinition = (user: User): string => {
  let loginType = '';
  switch (true) {
    case user.isAmazonConnected:
      loginType = 'amz-login';
      break;
    case user.isLoggedIn:
      loginType = 'moda-login';
      break;
    case !user.isLoggedIn:
      loginType = 'guest';
      break;
  }
  return loginType;
};

/**
 * cleanReturnSummary: This is to prevent the return summary from breaking snowflake.
 * The returnReasonHash needs to be replaced with something else (in this case, an
 * array of objects), or Segment will turn every order item ID into a column.
 */
const cleanReturnSummary = ({ returnSummary }: TrackingScopes) => {
  const summary = returnSummary as ReturnSummary | undefined;
  const attributes = summary?.attributes;
  if (attributes === undefined) return undefined;
  if (anyPass([isNil, isEmpty])(attributes.returnReasonHash)) {
    return dissoc('returnReasonHash')(attributes);
  }
  if (has('returnReasonHash', attributes)) {
    const returnReasons = toPairs(attributes.returnReasonHash).map(([key, value]) => {
      return { returnItemId: key, reasonCode: value };
    });

    return dissoc('returnReasonHash')(mergeLeft({ returnReasons })(attributes)) as Trackable;
  }
  // The type definition states that returnReasonHash is always present, but we're
  // double-checking whether it is regardless.  TS considers the next line to be
  // unreachable, but it may not be.
  return attributes as Trackable;
};

const cleanFeatureFlags = (flags: FeatureFlagResults) => {
  return toPairs(flags).map(([key, flagResult]) => {
    const normalizedValue = is(Object, flagResult.value)
      ? JSON.stringify(flagResult.value)
      : flagResult.value;
    return { flagName: key, flagValue: normalizedValue, flagReason: flagResult.reason };
  });
};

type GoogleAnalyticsCategories = [
  'Active Navigation',
  'Bag',
  'Checkout',
  'Experiments',
  'Login and Registration',
  'Marketing',
  'Order Converted',
  'Product Discovery',
  'Performance',
  'Returns',
  'Compliance',
  'Online Cancel',
  'Address Verification',
  'Zendesk Widget',
  'Email Verification'
];

const categoryMap: { [key in keyof typeof events]?: GoogleAnalyticsCategories[number] } = {
  'Breadcrumb Clicked': 'Active Navigation',
  'Cart Viewed': 'Bag',
  'Checkout Started': 'Checkout',
  'Checkout Step Completed': 'Checkout',
  'Checkout Step Viewed': 'Checkout',
  'Cumulative Layout Shift': 'Performance',
  'Designer Followed': 'Marketing',
  'Designer Unfollowed': 'Marketing',
  'Experiment Viewed': 'Experiments',
  'First Input Delay': 'Performance',
  'Interacted with Form': 'Checkout',
  'Largest Contentful Paint': 'Performance',
  'Mini Cart Icon Clicked': 'Checkout',
  'Mini Cart Proceed To Shopping Bag Clicked': 'Checkout',
  'Module Clicked': 'Marketing',
  'Module Viewed': 'Marketing',
  'Navigation Item Clicked': 'Active Navigation',
  'Mobile Navigation Item Clicked': 'Active Navigation',
  'Order Converted': 'Order Converted',
  'Payment Error Encountered': 'Checkout',
  'Payment Info Entered': 'Checkout',
  'Product Return Requested': 'Returns',
  'Promotion Clicked': 'Marketing',
  'Registration Completed': 'Login and Registration',
  'Second Payment Completed': 'Checkout',
  'Shipping Address Selected': 'Checkout',
  'Signed In': 'Login and Registration',
  'Signed Out': 'Login and Registration',
  'Sign-In Failed': 'Login and Registration',
  'Sign-In Failed and Account Disabled': 'Login and Registration',
  'Sign-In Blocked by Captcha': 'Login and Registration',
  'Start Guest Request Return Page Viewed': 'Returns',
  'Third Party Payment Method Flow Selected': 'Checkout',
  'Welcome Email Modal Dismissed': 'Marketing',
  'Welcome Email Modal Sign Up Finished': 'Marketing',
  'Welcome Email Modal Viewed': 'Marketing',
  'Welcome Email and SMS Modal Dismissed': 'Marketing',
  'Welcome Email and SMS Modal Sign Up Finished': 'Marketing',
  'Welcome Email and SMS Modal Viewed': 'Marketing',
  'Pagination Clicked': 'Active Navigation',
  "Editor's Note Link Clicked": 'Active Navigation',
  'Alternate Image Selected': 'Product Discovery',
  'Alternate Image Swiped': 'Product Discovery',
  'Video Swiped': 'Product Discovery',
  'Video Selected': 'Product Discovery',
  'Size Guide Selected': 'Product Discovery',
  'Size & Fit Tab Selected': 'Product Discovery',
  'Consent Given': 'Compliance',
  'Decline Given': 'Compliance',
  'Consent Withdrawn': 'Compliance',
  'Delivery Estimated with Zip Code': 'Product Discovery',
  'Delivery Estimated with Country': 'Product Discovery',
  'PDP Back In Stock Notification Request Displayed': 'Product Discovery',
  'PDP Back In Stock Notification Request Submitted': 'Product Discovery',
  'Affirm Payment Selected': 'Checkout',
  'Affirm Prequalification Selected': 'Product Discovery',
  'Online Cancel Request Page Viewed': 'Online Cancel',
  'Online Cancel Submitted': 'Online Cancel',
  'Online Cancel Success Page Viewed': 'Online Cancel',
  'Address Unable To Be Verified': 'Address Verification',
  'What is Trunkshow Clicked': 'Active Navigation',
  'Account Chat Displayed': 'Zendesk Widget',
  'Account Chat Used': 'Zendesk Widget',
  'Contact Care Chat Displayed': 'Zendesk Widget',
  'Contact Care Chat Used': 'Zendesk Widget',
  'Product Chat Displayed': 'Zendesk Widget',
  'Product Chat Used': 'Zendesk Widget',
  'Multiple Refund Methods Displayed': 'Returns',
  'Original Payment Refund Method Selected': 'Returns',
  'Site Credit Refund Method Selected': 'Returns',
  'Moda Private Form Submitted': 'Marketing',
  'Moda Network Form Submitted': 'Marketing',
  'Moda Private Form Closed': 'Marketing',
  'Moda Network Form Closed': 'Marketing',
  'Moda Private Show Form Banner Image Clicked': 'Marketing',
  'Moda Private Show Form Banner Image Mobile Clicked': 'Marketing',
  'Moda Private Show Form Personal Shopping Section Clicked': 'Marketing',
  'Moda Private Show Form Team Section Clicked': 'Marketing',
  'Moda Private Show Form Service Section Clicked': 'Marketing',
  'Moda Private Show Form Bottom Section Clicked': 'Marketing',
  'Moda Private Show Form Request your consultation Banner Image Clicked': 'Marketing',
  'Verify Email Window Viewed': 'Email Verification',
  'Verify Email Window Done Clicked': 'Email Verification',
  'Verify Email Window Resend Clicked': 'Email Verification',
  'Verify Email Window Back Clicked': 'Email Verification',
  'Verify Email Window Quit Clicked': 'Email Verification',
  'Welcome Window Viewed': 'Email Verification',
  'Welcome Window Start Shopping Clicked': 'Email Verification',
  'Welcome Window Quit Clicked': 'Email Verification',
  'Link Expired Window Viewed': 'Email Verification',
  'Link Expired Window Quit Clicked': 'Email Verification',
  'Link Expired Window Re-Enter Info Clicked': 'Email Verification',
  'Carousel Module Clicked': 'Marketing',
  'Moda Network Show Form Clicked': 'Marketing',
  'Banner Image Clicked': 'Marketing',
  'Banner Image Mobile Clicked': 'Marketing',
  'Service Section Clicked': 'Marketing',
  'Team Section Clicked': 'Marketing',
  'Contact To Purchase Open': 'Marketing',
  'Contact To Purchase Submitted': 'Marketing',
  'Contact To Purchase Closed': 'Marketing'
};

const getCategory = (scopes: TrackingScopes, eventName: string) =>
  (categoryMap as Record<string, string>)[eventName] || undefined;

const definitions = {
  product_id: ['variant.productId', 'lineItem.attributes.productId'],
  variant: ['variant.id', 'lineItem.attributes.variantId'],
  sku: ['size.sku', 'lineItem.attributes.skuId'],
  name: ['variant.name', 'lineItem.attributes.productName'],
  brand: ['variant.designerName', 'lineItem.attributes.designerName', getBrandForBrandedPLP],
  price: [
    'activeVariant.prices.currentPrice.price',
    'lineItem.attributes.prices.currentPrice.price',
    'price'
  ],
  original_price: [
    'activeVariant.prices.originalPrice.price',
    'lineItem.attributes.prices.originalPrice.price'
  ],
  image_url: [
    'activeVariant.primaryImage.medium',
    'activeVariant.primaryImageUrls.src',
    'lineItem.attributes.imageSrc'
  ],
  product_availability: ['activeVariant.availability'],
  currency: [
    'activeVariant.prices.currentPrice.currency',
    'lineItem.attributes.prices.currentPrice.currency',
    'order.attributes.currencyUnit',
    'cart.attributes.currency',
    'order.attributes.currency'
  ],
  inventory_type: [findVariantInventoryType],
  detailed_inventory_type: [findVariantDetailedInventoryType],
  filters: [
    {
      listAt: ['filters'],
      properties: {
        type: ['type'],
        value: ['value']
      }
    }
  ],
  sorts: ['sorts'],
  products: [
    {
      listAt: ['products'],
      properties: {
        product_id: ['productId', 'attributes.productId'],
        variant: ['attributes.variantId', 'id'],
        name: ['name', 'attributes.productName'],
        brand: ['designerName', 'attributes.designerName'],
        price: ['masterVariant.prices.currentPrice.price', 'attributes.prices.currentPrice.price'],
        original_price: [
          'masterVariant.prices.originalPrice.price',
          'attributes.prices.originalPrice.price'
        ],
        markdown_discount: ['attributes.prices.markdownDiscount.price'],
        promotion_discount: ['attributes.prices.promotionDiscount.price'],
        image_url: ['masterVariant.primaryImageUrls.src', 'attributes.imageSrc'],
        product_availability: ['availability'],
        currency: [
          'masterVariant.prices.currentPrice.currency',
          (item: AnyObject) => {
            const currency = item?.attributes?.prices?.currentPrice?.currency;
            return is(String)(currency) ? currency.toUpperCase() : undefined;
          }
        ],
        inventory_type: [findVariantInventoryType],
        detailed_inventory_type: [findVariantDetailedInventoryType],
        quantity: ['attributes.quantity'],
        sku: ['attributes.skuId'],
        objectID: ['objectid'],
        position: ['position'],
        category: ['category'],
        is_cj_affiliated: [
          (item: AnyObject) => {
            const cjCart = getStoredCart();
            const cjAffiliatedLineItem = cjCart.find(
              cjLineItem => cjLineItem.skuId == item?.attributes?.skuId
            );

            return Boolean(cjAffiliatedLineItem);
          }
        ]
      }
    }
  ],
  cart_id: ['cart.id'],
  cart_type: ['cart.attributes.version'],
  order_id: ['order.id'],
  order_number: ['order.attributes.orderNumber'],
  revenue: [
    ({ cart }: TrackingScopes) =>
      Number((cart as Cart | undefined)?.attributes.paymentSummary.total.price || 0) -
      Number((cart as Cart | undefined)?.attributes.paymentSummary.totalShipping.price || 0) -
      Number((cart as Cart | undefined)?.attributes.paymentSummary.totalTaxes.price || 0)
  ],
  total: [
    'order.attributes.paymentSummary.total.price',
    'cart.attributes.paymentSummary.total.price'
  ],
  cj_discount: [
    ({ products }: TrackingScopes) => {
      if (!Array.isArray(products)) return '0';

      const filteredProducts = products.filter((product: AnyObject) => {
        const cjCart = getStoredCart();
        return cjCart.some(cjItem => cjItem.skuId === product.attributes?.skuId);
      });

      const totalDiscount = filteredProducts.reduce((total, product) => {
        const promotionDiscount = Number(product.attributes?.prices?.promotionDiscount?.price || 0);
        return total + promotionDiscount;
      }, 0);

      return String(totalDiscount);
    }
  ],
  cj_total: [
    ({ products }: TrackingScopes) => {
      if (!Array.isArray(products)) return '0';

      const filteredProducts = products.filter((product: AnyObject) => {
        const cjCart = getStoredCart();
        return cjCart.some(cjItem => cjItem.skuId === product.attributes?.skuId);
      });

      const cjProducts = filteredProducts.map(mapToCjProduct);

      const subtotal = cjProducts.reduce((total, product) => {
        const unitPrice = Number(product.unitPrice);
        const quantity = Number(product.quantity);
        return total + unitPrice * quantity;
      }, 0);

      return String(subtotal);
    }
  ],
  paid_remaining_balance: ['order.attributes.paymentSummary.orderBalance'],
  shipping: [
    'order.attributes.paymentSummary.shippingAndPackaging.price',
    'cart.attributes.paymentSummary.esimatedShipping.price'
  ],
  tax: [
    'order.attributes.paymentSummary.salesTaxesAndDuties.price',
    'cart.attributes.paymentSummary.totalTaxes.price'
  ],
  subtotal: [
    'order.attributes.paymentSummary.subtotal.price',
    'cart.attributes.paymentSummary.subtotal.price'
  ],
  coupon: ['cart.attributes.giftCode', 'order.attributes.paymentSummary.giftCode'],
  discount: ['order.attributes.paymentSummary.discounts.price'],
  checkout_step: ['checkoutStep'],
  shipping_method: [
    ({ cart }: TrackingScopes) =>
      (cart as Cart | undefined)?.attributes.shippingMethods?.find(
        ({ method }) => method === (cart as Cart | undefined)?.attributes.selectedShippingMethodId
      )?.displayName
  ],
  payment_method: ['cart.attributes.paymentSource'],
  first_order: ['order.attributes.firstOrder'],
  query: ['query'],
  search_type: ['searchType'],
  cj_aid: ['referralParameters.aid'],
  cj_pid: ['referralParameters.pid'],
  cj_sid: ['referralParameters.sid'],
  cj_event: ['referralParameters.cjevent'],
  utm_source: ['referralParameters.utm_source'],
  category: ['category', getCategory],
  badge: ['badge'],
  signed_in: [
    ({ user }: TrackingScopes) => ((user as User)?.isLoggedIn ? 'signed in' : 'not signed in')
  ],
  email: ['user.email'],
  first_name: ['user.firstName'],
  last_name: ['user.lastName'],
  country_code: ['user.countryCode'],
  module_name: ['moduleName'],
  designer_name: ['designerName'],
  designer_id: ['designerId'],
  vertical: ['vertical'],
  login_type: [({ user }: TrackingScopes) => loginTypeDefinition(user as User)],
  index: ['variant.index', getSearchIndex],
  objectID: ['objectid', 'variant.objectid'],
  card_type: [
    ({ payment }: TrackingScopes) => (payment as Payment | undefined)?.attributes.cardType
  ],
  cardholder_name: [
    ({ payment }: TrackingScopes) => (payment as Payment | undefined)?.attributes.cardholderName
  ],
  expiration_date: [
    ({ payment }: TrackingScopes) => (payment as Payment | undefined)?.attributes.expirationDate
  ],
  is_valid: [({ payment }: TrackingScopes) => (payment as Payment | undefined)?.attributes.isValid],
  last_four: [
    ({ payment }: TrackingScopes) => (payment as Payment | undefined)?.attributes.lastFour
  ],
  billing_address: [
    ({ payment }: TrackingScopes) => (payment as Payment | undefined)?.attributes.billingAddress
  ],
  address_line1: [
    ({ address }: TrackingScopes) => (address as Address | undefined)?.attributes.addressLine1
  ],
  address_line2: [
    ({ address }: TrackingScopes) => (address as Address | undefined)?.attributes.addressLine2
  ],
  city: [({ address }: TrackingScopes) => (address as Address | undefined)?.attributes.city],
  state: [({ address }: TrackingScopes) => (address as Address | undefined)?.attributes.state],
  postal_code: [
    ({ address }: TrackingScopes) => (address as Address | undefined)?.attributes.postalCode
  ],
  return_id: [
    ({ returnSummary }: TrackingScopes) => (returnSummary as ReturnSummary | undefined)?.id
  ],
  return_summary: [cleanReturnSummary],
  tags: ['tags'],
  position: ['position'],
  feature_flags: [
    () => (window.__FEATURE_FLAGS__ != null ? cleanFeatureFlags(window.__FEATURE_FLAGS__) : {})
  ]
};

const propertyGroups = {
  Product: keysFrom<typeof definitions>([
    'product_id',
    'variant',
    'sku',
    'name',
    'brand',
    'price',
    'original_price',
    'image_url',
    'product_availability',
    'currency',
    'inventory_type',
    'detailed_inventory_type',
    'badge',
    'index',
    'objectID',
    'position'
  ]),
  Products: keysFrom<typeof definitions>(['products', 'index']),
  Filters: keysFrom<typeof definitions>(['filters']),
  Sorts: keysFrom<typeof definitions>(['sorts']),
  Cart: keysFrom<typeof definitions>(['cart_id', 'cart_type', 'subtotal']),
  Order: keysFrom<typeof definitions>([
    'order_id',
    'order_number',
    'total',
    'currency',
    'shipping',
    'tax',
    'first_order',
    'coupon',
    'discount',
    'subtotal',
    'cj_discount',
    'cj_total',
    'shipping_method'
  ]),
  SecondPayment: keysFrom<typeof definitions>([
    'order_id',
    'order_number',
    'paid_remaining_balance',
    'currency',
    'coupon',
    'discount'
  ]),
  Checkout: keysFrom<typeof definitions>([
    'subtotal',
    'total',
    'shipping',
    'tax',
    'coupon',
    'currency',
    'revenue'
  ]),
  CheckoutStep: keysFrom<typeof definitions>([
    'checkout_step',
    'shipping_method',
    'payment_method'
  ]),
  SearchInfo: keysFrom<typeof definitions>(['query', 'search_type']),
  ListBrand: keysFrom<typeof definitions>(['brand']),
  CJReferral: keysFrom<typeof definitions>([
    'cj_aid',
    'cj_pid',
    'cj_sid',
    'cj_event',
    'utm_source'
  ]),
  User: keysFrom<typeof definitions>([
    'email',
    'first_name',
    'last_name',
    'country_code',
    'login_type'
  ]),
  Designer: keysFrom<typeof definitions>(['designer_name', 'designer_id', 'vertical']),
  SignedIn: keysFrom<typeof definitions>(['signed_in']),
  Module: keysFrom<typeof definitions>(['module_name']),
  PaymentInfo: keysFrom<typeof definitions>([
    'card_type',
    'cardholder_name',
    'expiration_date',
    'is_valid',
    'last_four',
    'billing_address'
  ]),
  Address: keysFrom<typeof definitions>([
    'address_line1',
    'address_line2',
    'city',
    'state',
    'postal_code'
  ]),
  ReturnSummary: keysFrom<typeof definitions>(['return_summary', 'return_id', 'order_id']),
  GoogleAnalytics: keysFrom<typeof definitions>(['category']),
  Tags: keysFrom<typeof definitions>(['tags']),
  FeatureFlags: keysFrom<typeof definitions>(['feature_flags'])
};

const events = {
  // Make sure that all events containing "Product" or "Products" happen _after_
  // the page event for the page that triggers them, or else GTM will be missing
  // information that it needs to fire tags with correct information.
  // Use the deferUntilNewTitle function with tc.track to make sure that the
  // timing is correct.
  'Designer Followed': keysFrom<typeof propertyGroups>(['Designer', 'GoogleAnalytics']),
  'Designer Unfollowed': keysFrom<typeof propertyGroups>(['Designer', 'GoogleAnalytics']),
  'Interacted with Form': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Payment Error Encountered': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Signed In': keysFrom<typeof propertyGroups>(['User', 'GoogleAnalytics']),
  'Signed Out': keysFrom<typeof propertyGroups>(['User', 'GoogleAnalytics']),
  'Sign-In Failed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Sign-In Failed and Account Disabled': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Sign-In Blocked by Captcha': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Breadcrumb Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Navigation Item Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Mobile Navigation Item Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Mini Cart Icon Clicked': keysFrom<typeof propertyGroups>(['Cart', 'GoogleAnalytics']),
  'Mini Cart Proceed To Shopping Bag Clicked': keysFrom<typeof propertyGroups>([
    'Cart',
    'GoogleAnalytics'
  ]),
  'Module Viewed': keysFrom<typeof propertyGroups>(['Module', 'Products', 'GoogleAnalytics']),
  'Module Clicked': keysFrom<typeof propertyGroups>(['Module', 'GoogleAnalytics']),
  'Product Viewed': keysFrom<typeof propertyGroups>(['Product', 'SignedIn']),
  'Product Clicked': keysFrom<typeof propertyGroups>(['Product', 'Module']),
  'Product List Viewed': keysFrom<typeof propertyGroups>(['Products', 'ListBrand']),
  'Product List Filtered': keysFrom<typeof propertyGroups>([
    'Filters',
    'Sorts',
    'Products',
    'ListBrand'
  ]),
  'Product Added': keysFrom<typeof propertyGroups>(['Product', 'Cart', 'Products']),
  'Product Removed': keysFrom<typeof propertyGroups>(['Product', 'Cart']),
  'Products Searched': keysFrom<typeof propertyGroups>(['SearchInfo']),
  'Received Empty Search Result': keysFrom<typeof propertyGroups>(['SearchInfo']),
  'Order Converted': keysFrom<typeof propertyGroups>([
    'Order',
    'Products',
    'CJReferral',
    'Tags',
    'GoogleAnalytics'
  ]),
  'Second Payment Completed': keysFrom<typeof propertyGroups>([
    'SecondPayment',
    'Products',
    'GoogleAnalytics'
  ]),
  'Product Added to Wishlist': keysFrom<typeof propertyGroups>(['Product']),
  'Product Removed from Wishlist': keysFrom<typeof propertyGroups>(['Product']),
  'Wishlist Product Added to Cart': keysFrom<typeof propertyGroups>(['Product', 'Cart']),
  'Cart Viewed': keysFrom<typeof propertyGroups>(['Cart', 'Products', 'GoogleAnalytics']),
  'Checkout Started': keysFrom<typeof propertyGroups>([
    'Checkout',
    'Cart',
    'Products',
    'User',
    'GoogleAnalytics'
  ]),
  'Checkout Step Viewed': keysFrom<typeof propertyGroups>([
    'Cart',
    'Checkout',
    'Products',
    'CheckoutStep',
    'GoogleAnalytics'
  ]),
  'Checkout Step Completed': keysFrom<typeof propertyGroups>([
    'Cart',
    'Checkout',
    'CheckoutStep',
    'Products',
    'GoogleAnalytics'
  ]),
  'Promotion Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Payment Info Entered': keysFrom<typeof propertyGroups>([
    'Cart',
    'CheckoutStep',
    'PaymentInfo',
    'Products',
    'GoogleAnalytics'
  ]),
  'Registration Completed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Experiment Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Welcome Email Modal Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Welcome Email Modal Sign Up Finished': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Welcome Email Modal Dismissed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Welcome Email and SMS Modal Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Welcome Email and SMS Modal Sign Up Finished': keysFrom<typeof propertyGroups>([
    'GoogleAnalytics'
  ]),
  'Welcome Email and SMS Modal Dismissed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Third Party Payment Method Flow Selected': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Largest Contentful Paint': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'First Input Delay': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Cumulative Layout Shift': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Shipping Address Selected': keysFrom<typeof propertyGroups>([
    'Cart',
    'User',
    'CheckoutStep',
    'Address',
    'GoogleAnalytics'
  ]),
  'Product Return Requested': keysFrom<typeof propertyGroups>(['ReturnSummary', 'GoogleAnalytics']),
  'Product Delivery Timing Shown': keysFrom<typeof propertyGroups>(['Cart', 'Product']),
  'Email Signup Submitted': keysFrom<typeof propertyGroups>([]),
  'Start Guest Request Return Page Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Guest Request Return Page Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Guest Request Return Confirmation Page Viewed': keysFrom<typeof propertyGroups>([
    'GoogleAnalytics'
  ]),
  'Guest Product Return Requested': keysFrom<typeof propertyGroups>([
    'ReturnSummary',
    'GoogleAnalytics'
  ]),
  'Pagination Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  "Editor's Note Link Clicked": keysFrom<typeof propertyGroups>(['Product', 'GoogleAnalytics']),
  'Alternate Image Selected': keysFrom<typeof propertyGroups>(['Product', 'GoogleAnalytics']),
  'Alternate Image Swiped': keysFrom<typeof propertyGroups>(['Product', 'GoogleAnalytics']),
  'Video Swiped': keysFrom<typeof propertyGroups>(['Product', 'GoogleAnalytics']),
  'Video Selected': keysFrom<typeof propertyGroups>(['Product', 'GoogleAnalytics']),
  'Size Guide Selected': keysFrom<typeof propertyGroups>(['Product', 'GoogleAnalytics']),
  'Size & Fit Tab Selected': keysFrom<typeof propertyGroups>(['Product', 'GoogleAnalytics']),
  'Consent Given': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Decline Given': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Consent Withdrawn': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Delivery Estimated with Zip Code': keysFrom<typeof propertyGroups>([
    'Product',
    'GoogleAnalytics'
  ]),
  'Delivery Estimated with Country': keysFrom<typeof propertyGroups>([
    'Product',
    'GoogleAnalytics'
  ]),
  'PDP Back In Stock Notification Request Displayed': keysFrom<typeof propertyGroups>([
    'Product',
    'GoogleAnalytics'
  ]),
  'PDP Back In Stock Notification Request Submitted': keysFrom<typeof propertyGroups>([
    'Product',
    'GoogleAnalytics'
  ]),
  'Attentive Signup Submitted': keysFrom<typeof propertyGroups>([]),
  'Affirm Payment Selected': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Affirm Prequalification Selected': keysFrom<typeof propertyGroups>([
    'Product',
    'GoogleAnalytics'
  ]),
  'Online Cancel Request Page Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Online Cancel Submitted': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Online Cancel Success Page Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Address Unable To Be Verified': keysFrom<typeof propertyGroups>(['Address']),
  'What is Trunkshow Clicked': keysFrom<typeof propertyGroups>(['Product', 'GoogleAnalytics']),
  'Account Chat Displayed': keysFrom<typeof propertyGroups>(['User']),
  'Account Chat Used': keysFrom<typeof propertyGroups>(['User']),
  'Contact Care Chat Displayed': keysFrom<typeof propertyGroups>(['User']),
  'Contact Care Chat Used': keysFrom<typeof propertyGroups>(['User']),
  'Product Chat Displayed': keysFrom<typeof propertyGroups>(['Product', 'User']),
  'Product Chat Used': keysFrom<typeof propertyGroups>(['Product', 'User']),
  'Moda Private Form Submitted': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Moda Network Form Submitted': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Moda Private Form Closed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Moda Network Form Closed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Moda Private Show Form Banner Image Clicked': keysFrom<typeof propertyGroups>([
    'GoogleAnalytics'
  ]),
  'Moda Private Show Form Banner Image Mobile Clicked': keysFrom<typeof propertyGroups>([
    'GoogleAnalytics'
  ]),
  'Moda Private Show Form Personal Shopping Section Clicked': keysFrom<typeof propertyGroups>([
    'GoogleAnalytics'
  ]),
  'Moda Private Show Form Team Section Clicked': keysFrom<typeof propertyGroups>([
    'GoogleAnalytics'
  ]),
  'Moda Private Show Form Service Section Clicked': keysFrom<typeof propertyGroups>([
    'GoogleAnalytics'
  ]),
  'Moda Private Show Form Bottom Section Clicked': keysFrom<typeof propertyGroups>([
    'GoogleAnalytics'
  ]),
  'Moda Private Show Form Request your consultation Banner Image Clicked': keysFrom<
    typeof propertyGroups
  >(['GoogleAnalytics']),
  'Multiple Refund Methods Displayed': keysFrom<typeof propertyGroups>([
    'ReturnSummary',
    'GoogleAnalytics'
  ]),
  'Original Payment Refund Method Selected': keysFrom<typeof propertyGroups>([
    'ReturnSummary',
    'GoogleAnalytics'
  ]),
  'Site Credit Refund Method Selected': keysFrom<typeof propertyGroups>([
    'ReturnSummary',
    'GoogleAnalytics'
  ]),
  'Verify Email Window Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Verify Email Window Done Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Verify Email Window Resend Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Verify Email Window Back Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Verify Email Window Quit Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Welcome Window Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Welcome Window Start Shopping Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Welcome Window Quit Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Link Expired Window Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Link Expired Window Quit Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Link Expired Window Re-Enter Info Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Bundle Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Bundle Item Clicked': keysFrom<typeof propertyGroups>(['Product', 'GoogleAnalytics']),
  'Bundle Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Popup Bundle Item Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Bundle Page Clickthru': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Carousel Module Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Moda Network Show Form Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Banner Image Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Banner Image Mobile Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Service Section Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Team Section Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Contact To Purchase Open': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Contact To Purchase Submitted': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Contact To Purchase Closed': keysFrom<typeof propertyGroups>(['GoogleAnalytics'])
};

const appendToEveryEvent = (scopes: TrackingScopes) => ({
  srx: true,
  event_source: 'astrarium',
  user_id: (scopes.user as User)?.id
});

export const dictionary = new Dictionary<
  keyof typeof events,
  keyof typeof propertyGroups,
  keyof typeof definitions
>(events, propertyGroups, definitions, appendToEveryEvent);
