import React from 'react';
import { Clickable, Popover, Stack, Text } from '@moda/om';
import classNames from 'classnames';
import AccountIcon from '@moda/icons/account-24';
import { hrefFor } from '../../routers';
import { Z_INDEXES } from '../../generated/zIndexes';
import { useAuthModal } from '../../hooks/useAuthModal';
import { AccountLinks } from '../AccountLinks';
import { Link } from '../Link';
import { useEnableClubModaTreatment } from '../../hooks/useEnableClubModaTreatment';

import './SitePromoBannerLink.scss';

const MAX_CHARACTERS = 10;

const truncateName = (name: string) =>
  name.length > MAX_CHARACTERS ? `${name.substring(0, MAX_CHARACTERS)}...` : name;

export const SitePromoBannerLinkSignedOut: React.FC = () => {
  const { openAuthModal } = useAuthModal();
  const enableClubModaTreatment = useEnableClubModaTreatment();

  return (
    <Clickable
      className={classNames('SitePromoBannerLink', {
        'SitePromoBannerLink--club': enableClubModaTreatment
      })}
      aria-label="Sign In"
      data-testid="sign-in-button"
      onClick={() => openAuthModal()}
    >
      <AccountIcon className="SitePromoBannerLink__account-icon" height="24px" width="24px" />
    </Clickable>
  );
};

export const SitePromoBannerLinkSignedIn: React.FC<{ firstName: string | null }> = ({
  firstName
}) => {
  const enableClubModaTreatment = useEnableClubModaTreatment();
  return (
    <Popover
      className="SitePromoBannerLink__popover"
      zIndex={Z_INDEXES['site-promo-banner-popover']}
      content={<AccountLinks />}
      data-testid="account-button"
      anchor="topRight"
    >
      <Stack space={1} direction="horizontal" alignItems="center">
        <AccountIcon height="24px" width="24px" />

        <Link
          className={classNames('SitePromoBannerLink', {
            'SitePromoBannerLink--club': enableClubModaTreatment
          })}
          to={hrefFor.AccountSettings()}
        >
          {firstName && (
            <div className="SitePromoBannerLink__welcoming-message">
              <Text treatment="body2" className="SitePromoBannerLink__greetings-top">
                Hi,
              </Text>

              <Text treatment="body2" className="SitePromoBannerLink__greetings-bottom">
                {truncateName(firstName)}
              </Text>
            </div>
          )}

          {!firstName && 'Welcome!'}
        </Link>
      </Stack>
    </Popover>
  );
};

export const SitePromoBannerLink: React.FC = () => {
  return (
    <Stack space={1} direction="horizontal" justifyContent="flex-end">
      <Clickable
        className="SitePromoBannerLink SitePromoBannerLink__left"
        to={hrefFor.ModaPrivate()}
      >
        Need a Stylist?
      </Clickable>
    </Stack>
  );
};
