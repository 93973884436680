import stringify from 'json-stable-stringify';
import { FeatureFlagEvaluationReason, FeatureFlagVariationValue } from '@moda/portal-stanchions';
import { tc } from './trackingContext';

const stringifyFlagValue = function (value: FeatureFlagVariationValue) {
  if (typeof value === 'object') return stringify(value) ?? '';
  if (typeof value === 'number' || typeof value === 'boolean') return String(value);
  return value;
};

let trackedFlags: { [key: string]: boolean } = {};

export const clearFeatureFlagTrackingCache = () => {
  trackedFlags = {};
};

export const trackFeatureFlagView = (
  flagName: string,
  treatment: FeatureFlagVariationValue,
  reason?: FeatureFlagEvaluationReason
) => {
  if (trackedFlags[flagName]) return;
  if (reason != null && ['OFF', 'PREREQUISITE_FAILED', 'ERROR'].includes(reason)) return;

  const TRACKING_DELAY = 10;
  trackedFlags[flagName] = true;
  setTimeout(() => {
    tc.track('Experiment Viewed', {
      addToTrackingEvent: {
        nonInteraction: 1,
        category: 'Experiments',
        label: `${flagName}=${stringifyFlagValue(treatment)}`,
        experiment_id: flagName,
        variation_id: stringifyFlagValue(treatment)
      }
    });
  }, TRACKING_DELAY);
};
