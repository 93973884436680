import React from 'react';
import { Stack } from '@moda/om';
import { useDesktopNavigator } from '../../hooks/useNavigator';
import { DesktopSiteNavItemLoading } from '../SiteNav/DesktopSiteNav/DesktopSiteNavItemLoading';
import { throwQueryError } from '../../lib/apollo/throwQueryError';
import { DesktopNavigationVertical } from './DesktopNavigationVertical';

export const DesktopNavigation = () => {
  const { data, loading, error } = useDesktopNavigator();

  if (error) throwQueryError(error);

  if (loading) return <DesktopSiteNavItemLoading />;

  if (!data) return null;

  return (
    <Stack direction="horizontal" space={5} className="DesktopNavigation">
      {data.map(vertical => (
        <DesktopNavigationVertical key={vertical.id} vertical={vertical} />
      ))}
    </Stack>
  );
};
